export const environment = {
    production: false,
    apiUrl: 'http://localhost:3000', // your development API URL
    firebase : {
        apiKey: "AIzaSyCyoE64vj8yYPJ05MX0aXc9silnLBX4F_4",
        authDomain: "yugioh-local-tools.firebaseapp.com",
        projectId: "yugioh-local-tools",
        storageBucket: "yugioh-local-tools.appspot.com",
        messagingSenderId: "1038136929124",
        appId: "1:1038136929124:web:ae6c1ce5a7e8f31944e871",
        measurementId: "G-NP8E2XT2MZ"
      }
  };